"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lang = void 0;
var lang = {
  'langMsg': 'English',
  'test.test': ''
};
exports.lang = lang;