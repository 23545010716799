var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
      _c("div", { staticClass: "chart-wrap" }, [
        _c("div", { staticClass: "chart-line-wrap" }, [
          _c("div", { staticClass: "chart-line-title" }, [
            _vm._v(_vm._s(_vm.lineTitle)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "chart-line-value" }, [
            _vm._v("$" + _vm._s(_vm.paddingNum(_vm.lineD.totalBalance))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "chart-line-date" }, [
            _vm._v(_vm._s(_vm.lineD.time)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "chart-tabs" },
            _vm._l(_vm.tabData, function (item, index) {
              return _c(
                "span",
                {
                  key: index,
                  class:
                    _vm.defaultTab === item ? "chart-tab action" : "chart-tab",
                  on: {
                    click: function ($event) {
                      return _vm.handleTab(item)
                    },
                  },
                },
                [_vm._v("\n            " + _vm._s(item) + " Days\n          ")]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "chart-box" },
            [
              _c("div", { staticClass: "maxValue" }, [
                _vm._v(_vm._s("$" + _vm.findMaxAndMin(_vm.lineData.y).max)),
              ]),
              _vm._v(" "),
              _c("LineChart", {
                ref: "lineRef",
                attrs: {
                  height: "238",
                  "line-data": _vm.lineData,
                  length: _vm.lineData.x.length,
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "minValue" }, [
                _vm._v(_vm._s("$" + _vm.findMaxAndMin(_vm.lineData.y).min)),
              ]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "chart-pie-wrap" },
          [
            _c(
              "Banner",
              { attrs: { "slides-length": _vm.slides.length } },
              _vm._l(_vm.slides, function (slide, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "slide" },
                  [
                    _c("PieChart", {
                      ref: "pieRef",
                      refInFor: true,
                      attrs: {
                        height: "400px",
                        "pie-data": slide.data,
                        title: slide.secTitle,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pageContentWrap" }, [
      _c("div", { staticClass: "pageHeader" }, [
        _c(
          "div",
          { staticClass: "pageTabList" },
          _vm._l(_vm.tabList, function (n, i) {
            return _c(
              "span",
              {
                key: i,
                staticClass: "pageTabLi",
                class: i === _vm.tabListActiveIndex && "pageTabLiActive",
                on: {
                  click: function ($event) {
                    _vm.tabListActiveIndex = i
                  },
                },
              },
              [_vm._v("\n          " + _vm._s(n.label) + "\n        ")]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "selectTypeStatus" }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.tabListActiveIndex === 0
            ? _c("BlockComponents", {
                attrs: {
                  "config-more": { shadowNone: true },
                  "is-show-fitter": false,
                  limit: 10,
                  "is-page": false,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.tabListActiveIndex === 1
            ? _c("TransactionsComponents", {
                attrs: {
                  "config-more": { shadowNone: true },
                  "is-show-fitter": false,
                  limit: 10,
                  "is-page": false,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "activeLink",
          on: {
            click: function ($event) {
              return _vm.activeLink(_vm.tabListActiveIndex)
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.tabListActiveIndex === 0
                  ? "View more Blocks"
                  : "View all Transactions"
              )
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }