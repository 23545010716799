"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
var _toConsumableArray2 = _interopRequireDefault(require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _utils = require("@/utils");
var _TransactionsComponents = _interopRequireDefault(require("./TransactionsComponents.vue"));
var _account = require("@/api/account");
var _config = require("@/config");
var _bignumber = _interopRequireDefault(require("bignumber.js"));
var _networkUtilsMixin = _interopRequireDefault(require("@/constants/networkUtilsMixin"));
var _CopyToClipboard = _interopRequireDefault(require("@/components/CopyToClipboard"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ComplexTable',
  components: {
    TransactionsComponents: _TransactionsComponents.default,
    CopyToClipboard: _CopyToClipboard.default
  },
  mixins: [_networkUtilsMixin.default],
  setup: function setup() {
    var onSlideChange = function onSlideChange() {
      console.log('slide change');
    };
    return {
      onSlideChange: onSlideChange
    };
  },
  data: function data() {
    var _this = this;
    var h = this.$createElement;
    return {
      paramsDefault: {
        'address': this.$route.params.id
      },
      sum: 0,
      transType: '',
      typeOption: [],
      type: '',
      detailInfo: {},
      configLeft: {
        tableView: [{
          label: 'Token',
          key: 'symbol',
          align: 'left',
          formatter: function formatter(row, column, cellValue, index) {
            return h("div", {
              "class": 'nameStyle'
            }, [h("span", {
              "class": 'label2',
              "style": {
                backgroundImage: "url(".concat((0, _utils.getTokenIcon)(cellValue), ")")
              }
            }), h("span", [cellValue])]);
          }
        }, {
          label: 'Price',
          key: 'price',
          align: 'left',
          formatter: function formatter(row, column, cellValue, index) {
            return h("div", ["$", (0, _utils.paddingNum)(cellValue)]);
          }
        }, {
          label: 'Amount',
          key: 'amount',
          align: 'left',
          formatter: function formatter(row, column, cellValue, index) {
            return (0, _utils.paddingNum)(cellValue, 18);
          }
        }, {
          label: 'Value',
          key: 'value',
          align: 'left',
          formatter: function formatter(row, column, cellValue, index) {
            return h("div", ["$", (0, _utils.paddingNum)(cellValue)]);
          }
        }],
        tableListUrl: function tableListUrl(query) {
          return Promise.resolve(_this.detailInfo).then(function (response) {
            var data = response.balanceDetails;
            var d = _this.filters(data, _this.type);
            _this.setOption(data);
            if (d) {
              var startIndex = (query.page - 1) * 10;
              var list = d.slice(startIndex, startIndex + 10);
              _this.sum = _this.totalValue(d);
              return {
                list: list,
                total: d.length
              };
            } else {
              return {
                list: [],
                total: 0
              };
            }
          });
        }
      }
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    numberFormatWidthUnit: _utils.numberFormatWidthUnit,
    paddingNum: _utils.paddingNum,
    erc20Address: _utils.erc20Address,
    getData: function getData() {
      var _this2 = this;
      var hash = this.$route.params.hash;
      (0, _account.accountDetailApi)([{
        accountHash: hash
      }]).then(function (response) {
        _this2.detailInfo = response.result;
        _this2.$refs.tableRef.reload();
      });
    },
    setOption: function setOption() {
      var _this3 = this;
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var isGlobalAccount = this.detailInfo.accountHash === _config.GLOBAL_ACCOUNT;
      this.typeOption = data.reduce(function (accumulator, detail) {
        var subAccountId = parseInt(detail.subAccountId);
        if (!accumulator.some(function (obj) {
          return obj.value === subAccountId;
        }) && (isGlobalAccount || !_config.SUB_ACCOUNT_IDS.includes(subAccountId))) {
          accumulator.push({
            label: !isGlobalAccount ? "App".concat(detail.subAccountId) : "".concat(_this3.findNetwork(subAccountId).name),
            style: 'chain-icon unknown-color',
            value: subAccountId
          });
        }
        return accumulator;
      }, []);
      this.typeOption = [{
        label: 'All',
        value: ''
      }].concat((0, _toConsumableArray2.default)(!isGlobalAccount ? _config.SUB_ACCOUNT_ID : []), (0, _toConsumableArray2.default)(this.typeOption));
    },
    typeChange: function typeChange(e) {
      this.type = e;
      this.$refs.tableRef.reload();
    },
    filters: function filters(data, value) {
      if (value || value === 0) {
        return data.filter(function (v) {
          return v.subAccountId * 1 === value;
        });
      } else {
        return data;
      }
    },
    totalValue: function totalValue(data) {
      try {
        var init = [];
        var deci = [];
        for (var i = 0; i < data.length; i++) {
          if (data[i].value && data[i].value.includes('.')) {
            var arr = data[i].value.split('.');
            init.push(arr[0]);
            deci.push("0.".concat(arr[1]));
          } else {
            init.push(data.value);
          }
        }
        var a = init.reduce(function (pre, cur) {
          return (0, _bignumber.default)(pre) * 1 + (0, _bignumber.default)(cur) * 1;
        }, 0);
        var b = deci.reduce(function (pre, cur) {
          return pre * 1 + cur * 1;
        }, 0);
        return (0, _bignumber.default)(a).plus(b).toString();
      } catch (e) {
        console.log(e, 'error');
      }
    }
  }
};
exports.default = _default;