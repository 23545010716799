var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "statusWrap" }, [
    _c("div", { staticClass: "statusInfo" }, [
      _c(
        "span",
        { staticClass: "statusInfoText" },
        [
          _c("img", {
            staticClass: "statusInfoIcon",
            attrs: { src: _vm.l2ExecIcon, alt: "" },
          }),
          _vm._v(" "),
          _c(
            "Tip",
            {
              attrs: {
                placement: "top",
                "is-normal": true,
                text: "L2 Executed",
              },
            },
            [
              _c("span", [
                _vm._v("\n          The transaction has been received "),
                _c("br"),
                _vm._v(" by the zkLink platform.\n        "),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("span", { staticClass: "statusInfoLine" }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "statusInfo" }, [
      _c(
        "span",
        {
          class:
            _vm.status === 3 || _vm.status === 2
              ? "statusInfoText"
              : "statusInfoText statusInfoTextNot",
        },
        [
          _c("img", {
            class:
              _vm.status === 3 || _vm.status === 2
                ? "statusInfoIcon"
                : "statusInfoIcon statusInfoIconNot",
            attrs: { src: _vm.l2Icon, alt: "" },
          }),
          _vm._v(" "),
          _c(
            "Tip",
            {
              attrs: {
                placement: "top",
                "is-normal": true,
                text: "L2 Completed",
              },
            },
            [
              _c("span", [
                _vm._v("\n          The transaction has been included "),
                _c("br"),
                _vm._v(" in a zkLink Layer2 block.\n        "),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("span", {
        class:
          _vm.status === 3 || _vm.status === 2
            ? "statusInfoLine"
            : "statusInfoLine statusInfoLineNot",
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "statusInfo" }, [
      _c(
        "span",
        {
          class:
            _vm.status === 2
              ? "statusInfoText"
              : "statusInfoText statusInfoTextNot",
        },
        [
          _c("img", {
            class:
              _vm.status === 2
                ? "statusInfoIcon"
                : "statusInfoIcon statusInfoIconNot",
            attrs: { src: _vm.l1Icon, alt: "" },
          }),
          _vm._v(" "),
          _c(
            "Tip",
            {
              attrs: {
                placement: "top",
                "is-normal": true,
                text: "L1 Completed",
              },
            },
            [
              _c("span", [
                _vm._v("\n          Represents the finality "),
                _c("br"),
                _vm._v(" of the transaction.\n        "),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }