"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.get = get;
exports.post = post;
require("core-js/modules/es6.regexp.constructor");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _config = require("../config");
var service = _axios.default.create({
  baseURL: _config.API_HOST,
  // url = base url + request url
  timeout: 20000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  return Promise.resolve(response.data);
}, function (error) {
  if (new RegExp('Network Error').test(error)) {
    (0, _elementUi.Message)({
      message: 'Network Error ~',
      type: 'error',
      duration: 5 * 1000
    });
  } else {
    (0, _elementUi.Message)({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
  }
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;
function get(url, data) {
  return service.get(url, {
    params: data
  });
}
function post(url, data) {
  return service.post(url, data);
}