"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _iconLogo = _interopRequireDefault(require("../../../assets/zk/iconLogo.png"));
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean
    },
    transactions: {
      type: Number,
      default: 0
    },
    fees: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      title: 'zk.Link',
      logo: _iconLogo.default
    };
  },
  methods: {
    paddingNum: _utils.paddingNum
  }
};
exports.default = _default;