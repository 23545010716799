"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.object.get-own-property-descriptors");
var _defineProperty2 = _interopRequireDefault(require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("/home/zklink/web/zklink-explorer-web-build/node_modules/core-js/modules/es6.array.iterator.js");
require("/home/zklink/web/zklink-explorer-web-build/node_modules/core-js/modules/es6.promise.js");
require("/home/zklink/web/zklink-explorer-web-build/node_modules/core-js/modules/es6.object.assign.js");
require("/home/zklink/web/zklink-explorer-web-build/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("./icons");
require("./permission");
require("./utils/error-log");
require("./componentsG/index");
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _locale = _interopRequireDefault(require("element-ui/lib/locale"));
var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));
var _en2 = _interopRequireDefault(require("./lang/en"));
var _zh = _interopRequireDefault(require("./lang/zh"));
var filters = _interopRequireWildcard(require("./filters"));
var _lodash = _interopRequireDefault(require("lodash"));
var _fastclick = _interopRequireDefault(require("fastclick"));
var _store2 = require("@/store");
var _request = _interopRequireDefault(require("@/utils/request"));
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
_vue.default.use(_vueClipboard.default);
_fastclick.default.attach(document.body);
global._ = _lodash.default;
_vue.default.prototype._ = _lodash.default;
_vue.default.prototype.modelSet = _store2.modelSet;
_vue.default.prototype.modelGet = _store2.modelGet;
_vue.default.prototype.modelSetWithServer = _store2.modelSetWithServer;
_vue.default.prototype.request = _request.default;
_vue.default.prototype.$store = _store.default;

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium',
  // set element-ui default size
  locale: _en.default
});

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
_vue.default.use(_vueI18n.default);
var i18n = new _vueI18n.default({
  locale: 'en-US',
  // this.$i18n.locale
  messages: {
    'zh-CN': _objectSpread(_objectSpread({}, _zh.default), _zhCN.default),
    'en-US': _objectSpread(_objectSpread({}, _en2.default), _en.default)
  }
});
_locale.default.i18n(function (key, value) {
  return i18n.t(key, value);
});
new _vue.default({
  el: '#app',
  i18n: i18n,
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});