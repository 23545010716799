"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var _vue = _interopRequireDefault(require("vue"));
var requireComponent = require.context("./", true, /\w+\.(vue)$/);
requireComponent.keys().forEach(function (fileName) {
  var name = fileName.split("/").pop().replace(/\.\w+$/, ""); //
  var componentConfig = requireComponent(fileName);
  console.log("componentsG:", name);
  _vue.default.component(name, componentConfig.default || componentConfig);
});