"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.find");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      langValue: 'en-US',
      options: [
      // { value: 'zh-CN', label: '简体中文' },
      {
        value: 'en-US',
        label: 'English'
      }]
    };
  },
  computed: {},
  methods: {
    settingModalChange: function settingModalChange() {
      this.modelSet('app.settingsBottomOpen', true);
    },
    langChangeValue: function langChangeValue() {
      return _.find(this.options, {
        value: this.$i18n.locale
      }).label;
    },
    langChange: function langChange(v) {
      console.log("v", v);
      this.$i18n.locale = v;
    },
    settingModalClose: function settingModalClose() {
      this.modelSet('app.settingsBottomOpen', false);
    },
    themeChange: function themeChange(v) {
      // toggleClass(document.body, 'light-theme')
      // { 'light': 'dark', 'dark': 'light' }[this.modelGet('app.theme')]
      v = {
        'light': 'dark',
        'dark': 'light'
      }[this.modelGet('app.theme')];
      this.modelSet('app', 'theme', v);
      localStorage.setItem('theme', v);
      this.$router.replace({
        path: '/redirect' + this.$route.fullPath
      });
    }
  }
};
exports.default = _default;