"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.blockDetailApi = blockDetailApi;
exports.blockListApi = blockListApi;
var _defineProperty2 = _interopRequireDefault(require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _request = _interopRequireDefault(require("@/utils/request"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var blockParams = {
  'jsonrpc': '2.0',
  'method': 'latest_blocks',
  'id': 1
};
function blockListApi(params) {
  return (0, _request.default)({
    url: '/',
    method: 'post',
    data: _objectSpread(_objectSpread({}, blockParams), {}, {
      params: params
    })
  });
}
var blockDetailParams = {
  'jsonrpc': '2.0',
  'method': 'block_detail',
  'id': 1
};
function blockDetailApi(params) {
  return (0, _request.default)({
    url: '/',
    method: 'post',
    data: _objectSpread(_objectSpread({}, blockDetailParams), {}, {
      params: params
    })
  });
}