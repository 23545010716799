"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _Tip = _interopRequireDefault(require("@/components/Tip"));
var _statusIconPending = _interopRequireDefault(require("@/assets/zk/statusIconPending.png"));
var _statusIconCompletedL = _interopRequireDefault(require("@/assets/zk/statusIconCompletedL1.png"));
var _statusIconCompleted = _interopRequireDefault(require("@/assets/zk/statusIconCompleted.png"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Status',
  components: {
    Tip: _Tip.default
  },
  props: {
    status: {
      type: Number,
      default: 1,
      required: true
    }
  },
  data: function data() {
    return {
      l2ExecIcon: _statusIconPending.default,
      l1Icon: _statusIconCompletedL.default,
      l2Icon: _statusIconCompleted.default
    };
  }
};
exports.default = _default;