var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      0
        ? [
            _c("hamburger", {
              staticClass: "hamburger-container",
              attrs: {
                id: "hamburger-container",
                "is-active": _vm.sidebar.opened,
              },
              on: { toggleClick: _vm.toggleSideBar },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.modelGet("app.device") === "desktop"
        ? [
            _c("div", { staticClass: "navbarDesktop" }, [
              _c("div", { staticClass: "navbar2" }, [
                _c(
                  "div",
                  { staticClass: "navbar " },
                  [
                    _c("Logo", {
                      attrs: {
                        transactions: _vm.totalData.transactions,
                        fees: _vm.totalData.fees,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "menuHorizontalList" },
                      [
                        [_c("SearchMenu")],
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "menuList" },
                          [
                            _vm._l(_vm.menuList, function (n, i) {
                              return _c(
                                "span",
                                {
                                  key: i,
                                  staticClass: "menuHorizontalLi",
                                  class: [
                                    _vm.$route.name === n.name
                                      ? "menuHorizontalLiActive"
                                      : "",
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.push(n.path)
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "liText" }, [
                                    _vm._v(_vm._s(n.label)),
                                  ]),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticClass: "selectStyle",
                                staticStyle: { width: "110px" },
                                attrs: { placeholder: "please select" },
                                on: { change: _vm.selectHandler },
                                model: {
                                  value: _vm.selectLink,
                                  callback: function ($$v) {
                                    _vm.selectLink = $$v
                                  },
                                  expression: "selectLink",
                                },
                              },
                              _vm._l(_vm.selectLinkOptions, function (item) {
                                return _c(
                                  "a",
                                  {
                                    key: item.value,
                                    attrs: { href: item.value },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: item.label,
                                        value: item.label,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ],
                          2
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        : [
            _c(
              "div",
              { staticClass: "navbarMobile" },
              [
                !_vm.searchExpand
                  ? _c("div", { staticClass: "header1" }, [
                      _c("div", { staticClass: "headerIcon logoIcon" }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "headerIcon sliderIcon",
                        on: { click: _vm.toggleSideBar },
                      }),
                    ])
                  : _c(
                      "div",
                      { staticClass: "header1" },
                      [
                        _c("SearchMenu"),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "headerIcon closeIcon",
                          on: {
                            click: function ($event) {
                              _vm.searchExpand = false
                            },
                          },
                        }),
                      ],
                      1
                    ),
                _vm._v(" "),
                [
                  _c(
                    "div",
                    { staticClass: "header2" },
                    [_c("SearchMenu", { attrs: { "is-home": true } })],
                    1
                  ),
                ],
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }