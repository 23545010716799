"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _network = require("@/api/network");
var state = {
  networks: []
};
var mutations = {
  setNetworks: function setNetworks(state, data) {
    state.networks = data;
  }
};
var actions = {
  getNetworks: function getNetworks(context) {
    return (0, _network.networkApi)().then(function (response) {
      context.commit('setNetworks', response.data);
    }).catch(function (error) {
      console.error(error);
    });
  }
};
var mockContext = {
  commit: function commit(mutationName, payload) {
    mutations[mutationName](state, payload);
  }
};
actions.getNetworks(mockContext);
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;