var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "app-header" }, [_vm._v("Account")]),
      _vm._v(" "),
      _c("div", { staticClass: "totalInfo" }, [
        _c("span", { staticClass: "infoLabel" }, [_vm._v("Total Balance：")]),
        _vm._v(" "),
        _c("span", { staticClass: "infoValue" }, [
          _vm._v("$" + _vm._s(_vm.paddingNum(_vm.detailInfo.totalBalance))),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "detailInfo" },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.erc20Address(_vm.detailInfo.accountHash)) +
              "\n    "
          ),
          _c("CopyToClipboard", {
            attrs: {
              "text-to-copy": _vm.erc20Address(_vm.detailInfo.accountHash),
              content: "Copy Address",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.modelGet("app.device") === "desktop"
        ? _c("div", { staticClass: "totalInfo2" }, [
            _c("span", { staticClass: "infoLabel" }, [
              _vm._v("Account Balance："),
            ]),
            _vm._v(" "),
            _c("span", [_vm._v("$" + _vm._s(_vm.paddingNum(_vm.sum)))]),
            _vm._v(" "),
            _c(
              "section",
              { staticStyle: { "margin-left": "auto" } },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "selectStyle",
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: "please select" },
                    on: { change: _vm.typeChange },
                    model: {
                      value: _vm.type,
                      callback: function ($$v) {
                        _vm.type = $$v
                      },
                      expression: "type",
                    },
                  },
                  _vm._l(_vm.typeOption, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.modelGet("app.device") === "mobile"
        ? _c("div", { staticClass: "totalInfo3" }, [
            _c("span", { staticClass: "infoLabel" }, [
              _vm._v("Account Balance："),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "infoValue" }, [
              _vm._v("$" + _vm._s(_vm.paddingNum(_vm.sum))),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.modelGet("app.device") === "mobile"
        ? _c("div", { staticClass: "totalInfo4" }, [
            _c(
              "section",
              [
                _c(
                  "el-select",
                  {
                    staticClass: "selectStyle",
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: "please select" },
                    on: { change: _vm.typeChange },
                    model: {
                      value: _vm.type,
                      callback: function ($$v) {
                        _vm.type = $$v
                      },
                      expression: "type",
                    },
                  },
                  _vm._l(_vm.typeOption, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "infoContent" },
        [
          _c("TableBasic", {
            ref: "tableRef",
            attrs: { config: _vm.configLeft, limit: 10 },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "app-header" }, [_vm._v("Transactions")]),
      _vm._v(" "),
      _c("TransactionsComponents", {
        attrs: {
          "params-default": _vm.paramsDefault,
          "in-out": true,
          "account-hash": _vm.$route.params.hash,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }