var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "menuList",
          class: _vm.isCollapse ? "collapseStyle" : "",
        },
        _vm._l(_vm.menuList, function (n, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass: "menuLi",
              class: [_vm.$route.name === n.name ? "menuLiActive" : ""],
              on: {
                click: function ($event) {
                  return _vm.$router.push(n.path)
                },
              },
            },
            [
              _c("span", { staticClass: "liIcon", class: n.icon }),
              _vm._v(" "),
              _c("span", { staticClass: "liText" }, [_vm._v(_vm._s(n.label))]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }