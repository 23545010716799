"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _chart = _interopRequireDefault(require("chart.js"));
var _utils = require("@/utils");
//
//
//
//
//
//
var _default2 = {
  props: {
    title: {
      type: String,
      default: 'zklink'
    },
    width: {
      type: String,
      default: '200px'
    },
    height: {
      type: String,
      default: '200px'
    },
    pieData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      pieChart: null,
      chartData: {
        labels: this.pieData.map(function (v) {
          return v.name;
        }),
        datasets: [{
          label: 'chains',
          data: this.pieData.map(function (v) {
            return v.value;
          }),
          hoverOffset: 4,
          backgroundColor: this.pieData.map(function (v) {
            return v.color;
          })
        }]
      },
      chartOptions: {
        title: {
          display: true,
          text: this.title,
          fontSize: 18,
          fontStyle: 'bold',
          position: 'top'
        },
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 80,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
            fontSize: 10,
            padding: 15
          }
        },
        tooltips: {
          callbacks: {
            label: function label(tooltipItem, data) {
              var dataIndex = tooltipItem.index;
              var label = data.labels[dataIndex];
              var value = data.datasets[0].data[dataIndex];
              var labelLine = "Symbol: ".concat(label);
              var valueLine = "Value: $".concat((0, _utils.paddingNum)(value));
              var text = [labelLine];
              text.push(valueLine);
              return text;
            }
          }
        }
      }
    };
  },
  watch: {
    width: 'updateChartSize',
    height: 'updateChartSize',
    pieData: {
      handler: function handler(newPieData) {
        this.chartData.labels = newPieData.map(function (v) {
          return v === null || v === void 0 ? void 0 : v.name;
        });
        this.chartData.datasets[0].data = newPieData.map(function (v) {
          return v === null || v === void 0 ? void 0 : v.chainId;
        });
        this.chartData.datasets[0].backgroundColor = newPieData.map(function (v) {
          return v === null || v === void 0 ? void 0 : v.color;
        });
        this.renderHollowPieChart();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.renderHollowPieChart();
  },
  methods: {
    renderHollowPieChart: function renderHollowPieChart() {
      if (this.pieChart) {
        this.pieChart.destroy();
      }
      var ctx = this.$refs.pieChartCanvas.getContext('2d');
      this.pieChart = new _chart.default(ctx, {
        type: 'pie',
        data: this.chartData,
        options: this.chartOptions
      });
    },
    updateChartSize: function updateChartSize() {
      if (this.pieChart) {
        this.pieChart.destroy();
      }
      var ctx = this.$refs.pieChartCanvas.getContext('2d');
      ctx.canvas.width = this.width;
      ctx.canvas.height = this.height;
      this.pieChart = new _chart.default(ctx, {
        type: 'pie',
        data: this.chartData,
        options: this.chartOptions
      });
    }
  }
};
exports.default = _default2;