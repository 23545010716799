var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TableBasic", {
    ref: "tableRef",
    attrs: { config: _vm.config, limit: _vm.limit, "is-page": _vm.isPage },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }