"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));
var _SearchMenu = _interopRequireDefault(require("./SearchMenu"));
var _Logo = _interopRequireDefault(require("./Sidebar/Logo"));
var _index = require("../../config/index");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var _default = {
  components: {
    Hamburger: _Hamburger.default,
    SearchMenu: _SearchMenu.default,
    Logo: _Logo.default
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['sidebar', 'avatar', 'device'])),
  data: function data() {
    return {
      menuList: [{
        label: 'Overview',
        name: 'dashboard',
        path: '/dashboard',
        icon: 'dashboard'
      }, {
        label: 'Transactions',
        name: 'transactions',
        path: '/transactions',
        icon: 'transactions'
      }, {
        label: 'Blocks',
        name: 'blocks',
        path: '/blocks',
        icon: 'blocks'
      }],
      selectLink: _index.CHANGE_LINK,
      selectLinkOptions: [{
        label: 'mainnet',
        value: 'https://scan.zk.link/'
      }, {
        label: 'testnet',
        value: 'https://test-scan.zk.link/'
      }],
      searchValue: '',
      searchExpand: false,
      langValue: 'en-US',
      options: [
      // { value: 'zh-CN', label: '简体中文' },
      {
        value: 'en-US',
        label: 'English'
      }],
      transType: '',
      totalData: {},
      routeName: this.$route.name === 'dashboard'
    };
  },
  watch: {
    $route: function $route(to, from) {
      this.routeName = this.$route.name === 'dashboard';
    }
  },
  methods: {
    totalDataGet: function totalDataGet() {},
    selectHandler: function selectHandler(type) {
      this.selectLink = type;
    },
    langChange: function langChange(v) {
      this.$i18n.locale = v;
    },
    themeChange: function themeChange(v) {
      // toggleClass(document.body, 'light-theme')
      this.modelSet('app', 'theme', {
        'light': 'dark',
        'dark': 'light'
      }[this.modelGet('app.theme')]);
      localStorage.setItem('theme', v);
      this.$router.replace({
        path: '/redirect' + this.$route.fullPath
      });
    },
    handleCommand: function handleCommand(command) {
      this.$i18n.locale = command;
    },
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    }
  }
};
exports.default = _default;