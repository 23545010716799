"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addClass = addClass;
exports.byteLength = byteLength;
exports.cleanArray = cleanArray;
exports.createUniqueString = createUniqueString;
exports.dayFormat = exports.customFormatUTC = void 0;
exports.dayFormatEnglish = dayFormatEnglish;
exports.dayFormatUTC = void 0;
exports.debounce = debounce;
exports.deepClone = deepClone;
exports.erc20Address = void 0;
exports.formatPrice = formatPrice;
exports.getQueryObject = getQueryObject;
exports.getRandomColor = void 0;
exports.getSubStr = getSubStr;
exports.getTime = getTime;
exports.getTokenIcon = getTokenIcon;
exports.hasClass = hasClass;
exports.html2Text = html2Text;
exports.numberFormat = numberFormat;
exports.numberFormatDecimals = numberFormatDecimals;
exports.numberFormatLabel = numberFormatLabel;
exports.numberFormatWidthUnit = numberFormatWidthUnit;
exports.objectMerge = objectMerge;
exports.paddingNum = void 0;
exports.param = param;
exports.param2Obj = param2Obj;
exports.percent100format = percent100format;
exports.removeClass = removeClass;
exports.timeAgoFormat = timeAgoFormat;
exports.toggleClass = toggleClass;
exports.uniqueArr = uniqueArr;
require("core-js/modules/es7.string.pad-end");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.set");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
var _typeof2 = _interopRequireDefault(require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.replace");
var _dayjs = _interopRequireDefault(require("dayjs"));
var _config = require("../config");
var _utc = _interopRequireDefault(require("dayjs/plugin/utc"));
var _ethers = require("ethers");
_dayjs.default.extend(_utc.default);
/**
 * @param {string} url
 * @returns {Object}
 */
function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) {
      s++;
    } else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}

/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * @param {string} val
 * @returns {string}
 */
function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
function objectMerge(target, source) {
  if ((0, _typeof2.default)(target) !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if ((0, _typeof2.default)(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var later = function later() {
    var last = +new Date() - timestamp;
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
function deepClone(source) {
  if (!source && (0, _typeof2.default)(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && (0, _typeof2.default)(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}
function numberFormat(number) {
  var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var dec_point = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '.';
  var thousands_sep = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : ',';
  number = "".concat(number).replace(/[^0-9+-Ee.]/g, '');
  var n = !isFinite(+number) ? 0 : +number;
  var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  var sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep;
  var dec = typeof dec_point === 'undefined' ? '.' : dec_point;
  var s = '';
  var toFixedFix = function toFixedFix(n, prec) {
    var k = Math.pow(10, prec);
    return "".concat(Math.ceil(n * k) / k);
  };
  s = (prec ? toFixedFix(n, prec) : "".concat(Math.round(n))).split('.');
  var re = /(-?\d+)(\d{3})/;
  if (!_.isEmpty(sep)) {
    while (re.test(s[0])) {
      s[0] = s[0].replace(re, "$1".concat(sep, "$2"));
    }
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

/*
* 乘以100 浮点数精度计算
* */
function percent100format(cellValue) {
  return parseFloat(cellValue.toFixed(2));
}
function dayFormatEnglish(date) {
  if (_.isNil(date)) {
    return '';
  }
  var mList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  var dayjsObj = (0, _dayjs.default)(date);
  return "".concat(mList[dayjsObj.month()].slice(0, 3), " ").concat(dayjsObj.date(), ",").concat(dayjsObj.year());
}
function numberFormatWidthUnit(value) {
  var pre = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '$';
  if (_.isNil(value)) {
    return '';
  }
  if (value < 10000) {
    return pre + numberFormat(value, 4).replace(/0+$/, '').replace(/\.$/, '');
  }
  if (value < 10000 * 1000) {
    return pre + numberFormat(value / 1000, 4).replace(/0+$/, '').replace(/\.$/, '') + 'K';
  }
  if (value < 10000 * 1000 * 1000) {
    return pre + numberFormat(value / (1000 * 1000), 4).replace(/0+$/, '').replace(/\.$/, '') + 'M';
  }
  return pre + numberFormat(value / (1000 * 1000 * 1000), 4).replace(/0+$/, '').replace(/\.$/, '') + 'B';
}
function numberFormatLabel(value) {
  var arr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  var monthV = (0, _dayjs.default)(value).month();
  return monthV === 0 ? (0, _dayjs.default)(value).year() : monthV;
}

/**
 * numberFormatDecimals
 * @param value
 * @param num
 */
function numberFormatDecimals(value) {
  var num = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  if (_.isNil(value)) {
    return '';
  }
  var numberStr = "".concat(value || 0);
  var numberArr = numberStr.split('.');
  if (_.size(numberArr) === 1) {
    return value;
  } else if (_.size(numberArr) === 2) {
    return numberArr[0] + '.' + numberArr[1].slice(0, num);
  } else {
    return value;
  }
}

/*
* time
* */
function timeAgoFormat(cellValue) {
  var timeNow = new Date().getTime();
  var compare = timeNow - cellValue;
  if (!cellValue) {
    return '--';
  }
  if (compare < 0) {
    return '0 secs ago';
  }
  if (compare < 1000 * 60) {
    var secs = Math.floor(compare / 1000);
    return "".concat(secs, " ").concat(secs <= 1 ? 'sec' : 'secs', " ago");
  }
  if (compare < 1000 * 60 * 60) {
    var minutes = Math.floor(compare / (1000 * 60));
    return "".concat(minutes, " ").concat(minutes <= 1 ? 'min' : 'mins', " ago");
  }
  if (compare < 1000 * 60 * 60 * 24) {
    var hours = Math.floor(compare / (1000 * 60 * 60));
    var _minutes = Math.floor(compare % (1000 * 60 * 60) / (1000 * 60));
    return _minutes === 0 ? "".concat(hours, " ").concat(hours <= 1 ? 'hr' : 'hrs', " ago") : "".concat(hours, " ").concat(hours <= 1 ? 'hr' : 'hrs', " ").concat(_minutes, " ").concat(_minutes <= 1 ? 'min' : 'mins', " ago");
  }
  return _dayjs.default.utc(cellValue).format('YYYY-MM-DD HH:mm:ss');
}

/*
* 3333.1234567 -> 3,333.123456
*/
var paddingNum = function paddingNum(num) {
  var amount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 6;
  if (!num || num === '0') return amount === 6 ? '0 .000000' : '0 .000000000000000000';
  var str = '';
  if (typeof num === 'number') {
    str = num.toString();
  } else {
    str = num;
  }

  // if (str.length <= 2) return str + ' .' + (amount === 6 ? '000000' : '000000000000000000')

  var integer;
  var floater = '';
  if (!str.includes('.')) {
    integer = str.split('');
  } else {
    var ary = str.split('.');
    integer = ary[0].split('');
    floater = ary[1].substring(0, amount);
  }
  var count;
  integer.length % 3 === 0 ? count = integer.length / 3 - 1 : count = Math.floor(integer.length / 3);
  for (var i = 0; i < count; i++) {
    integer.splice(integer.length - (i + 1) * 3 - i, 0, ',');
  }
  var finalStr;
  floater.length === 0 ? finalStr = integer.join('') + ' .' + '0'.padEnd(amount, '0') : finalStr = integer.join('') + ' .' + floater.padEnd(amount, '0');
  return finalStr;
};
exports.paddingNum = paddingNum;
function formatPrice(price) {
  return String(price).split('').reverse().reduce(function (prev, next, index) {
    return (index % 3 ? next : next + ',') + prev;
  });
}
var dayFormat = function dayFormat(time) {
  return time ? _dayjs.default.utc(time).format('YYYY-MM-DD HH:mm:ss') : '--';
};
exports.dayFormat = dayFormat;
function getTokenIcon(tokenSymbol) {
  return tokenSymbol ? "".concat(_config.STATIC_HOST, "/token/icons/default/").concat(tokenSymbol.toLowerCase(), ".svg") : '';
}
function getSubStr(address) {
  return address ? address.slice(0, 6) + '...' + address.slice(address.length - 4, address.length) : '';
}
var dayFormatUTC = function dayFormatUTC(time) {
  return time ? _dayjs.default.utc(time).format('MMM-DD-YYYY h:mm:ss A +[UTC]') : '--';
};
exports.dayFormatUTC = dayFormatUTC;
var customFormatUTC = function customFormatUTC(time) {
  if (!time) return '--';
  var now = Date.now();
  var diffMilliseconds = now - time;
  var seconds = Math.floor(diffMilliseconds / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);
  if (days > 0) {
    return "".concat(days, " ").concat(days <= 1 ? 'day' : 'days', " ").concat(hours % 24, " ").concat(hours % 24 <= 1 ? 'hr' : 'hrs', " ago");
  } else if (hours > 0) {
    var remainingMinutes = minutes % 60;
    return remainingMinutes === 0 ? "".concat(hours, " ").concat(hours <= 1 ? 'hr' : 'hrs', " ago") : "".concat(hours, " ").concat(hours <= 1 ? 'hr' : 'hrs', " ").concat(remainingMinutes, " ").concat(remainingMinutes <= 1 ? 'min' : 'mins', " ago");
  } else if (minutes > 0) {
    return "".concat(minutes, " ").concat(minutes <= 1 ? 'min' : 'mins', " ago");
  } else if (seconds > 0) {
    return "".concat(seconds, " ").concat(seconds <= 1 ? 'sec' : 'secs', " ago");
  } else {
    return '0 sec ago';
  }
};
exports.customFormatUTC = customFormatUTC;
var erc20Address = function erc20Address(_erc20Address) {
  if (!_erc20Address) return null;
  if (!_ethers.utils.isAddress(_erc20Address)) {
    return _erc20Address;
  }
  return _ethers.utils.getAddress(_erc20Address);
};
exports.erc20Address = erc20Address;
var getRandomColor = function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
exports.getRandomColor = getRandomColor;