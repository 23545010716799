"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Tip',
  props: {
    placement: {
      type: String,
      default: 'right'
    },
    isJumpTip: {
      type: Boolean,
      default: false
    },
    isNormal: {
      type: Boolean,
      default: false
    },
    text: {
      type: [String, Number],
      default: ''
    }
  }
};
exports.default = _default;