"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _utils = require("@/utils");
var _TransactionsComponents = _interopRequireDefault(require("./TransactionsComponents.vue"));
var _BlockTokenListShow = _interopRequireDefault(require("../../components/BlockTokenListShow"));
var _block = require("@/api/block");
var _networkUtilsMixin = _interopRequireDefault(require("@/constants/networkUtilsMixin"));
var _Status = _interopRequireDefault(require("@/components/Status"));
var _Tip = _interopRequireDefault(require("@/components/Tip"));
var _CopyToClipboard = _interopRequireDefault(require("@/components/CopyToClipboard"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ComplexTable',
  components: {
    TransactionsComponents: _TransactionsComponents.default,
    BlockTokenListShow: _BlockTokenListShow.default,
    CopyToClipboard: _CopyToClipboard.default,
    Status: _Status.default,
    Tip: _Tip.default
  },
  directives: {},
  filters: {},
  mixins: [_networkUtilsMixin.default],
  data: function data() {
    return {
      blockHeight: this.$route.params.id,
      detailInfo: {}
    };
  },
  computed: {},
  created: function created() {
    this.contractIdListGet();
  },
  methods: {
    dayFormat: _utils.dayFormat,
    customFormatUTC: _utils.customFormatUTC,
    dayFormatUTC: _utils.dayFormatUTC,
    contractIdListGet: function contractIdListGet() {
      var _this = this;
      var id = this.$route.params.id;
      (0, _block.blockDetailApi)([{
        blockHeight: Number(id)
      }]).then(function (res) {
        _this.detailInfo = res.result;
      });
    }
  }
};
exports.default = _default;