var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: _vm.className,
      style: { color: _vm.value >= 0 ? "#03D498" : "#FF0404" },
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(_vm.value > 0 ? "+" : "") +
          " " +
          _vm._s(_vm.value) +
          "%\n"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }