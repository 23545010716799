"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TransactionsComponents = _interopRequireDefault(require("./TransactionsComponents.vue"));
//
//
//
//
//
//
//
var _default = {
  name: 'ComplexTable',
  components: {
    TransactionsComponents: _TransactionsComponents.default
  },
  directives: {},
  filters: {},
  data: function data() {
    return {};
  },
  computed: {},
  created: function created() {},
  methods: {}
};
exports.default = _default;