var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "copy-clip", on: { click: _vm.onClickCopy } },
    [
      _c(
        "el-tooltip",
        {
          staticClass: "item",
          attrs: { effect: "dark", content: _vm.content, placement: "top" },
        },
        [
          !_vm.isCopy
            ? _c("svg-icon", {
                attrs: { "icon-class": "copy", "class-name": "copy-clip-icon" },
              })
            : _c("svg-icon", { attrs: { "icon-class": "confirm-icon" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }