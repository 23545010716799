"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
var _toConsumableArray2 = _interopRequireDefault(require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _defineProperty2 = _interopRequireDefault(require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _api = require("@/api");
var _utils = require("@/utils");
var _TransactionsComponents = _interopRequireDefault(require("../index/TransactionsComponents.vue"));
var _BlocksComponent = _interopRequireDefault(require("../index/BlocksComponent.vue"));
var _config = require("@/config");
var _LineChart = _interopRequireDefault(require("@/components/Chart/LineChart"));
var _Banner = _interopRequireDefault(require("@/components/Banner"));
var _PieChart = _interopRequireDefault(require("@/components/Chart/PieChart"));
var _networkUtilsMixin = _interopRequireDefault(require("@/constants/networkUtilsMixin"));
var _account = require("@/api/account");
var _bignumber = _interopRequireDefault(require("bignumber.js"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var _default = {
  name: 'Dashboard',
  components: {
    TransactionsComponents: _TransactionsComponents.default,
    LineChart: _LineChart.default,
    Banner: _Banner.default,
    PieChart: _PieChart.default,
    BlockComponents: _BlocksComponent.default
  },
  mixins: [_networkUtilsMixin.default],
  data: function data() {
    return {
      slideData: {},
      slides: [],
      tabData: [7, 15],
      defaultTab: 7,
      lineTitle: 'All',
      chainId: 0,
      lineD: {
        totalBalance: '',
        time: ''
      },
      lineData: {
        x: [],
        y: []
      },
      tabList: [{
        label: 'Latest Blocks'
      }, {
        label: 'Latest Transactions'
      }],
      tabListActiveIndex: 0,
      totalData: {},
      options: [],
      transType: '',
      statusValue: '',
      lineChartDataTop: {},
      barChartDataTop: {},
      barChartData: {
        x: [],
        y1: []
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['roles'])),
  watch: {
    networks: {
      handler: 'onNetworksChange',
      immediate: true
    }
  },
  mounted: function mounted() {
    // this.updateSlides()
    this.getLineDate();
  },
  methods: {
    numberFormat: _utils.numberFormat,
    percent100format: _utils.percent100format,
    numberFormatWidthUnit: _utils.numberFormatWidthUnit,
    dayFormatEnglish: _utils.dayFormatEnglish,
    getRandomColor: _utils.getRandomColor,
    paddingNum: _utils.paddingNum,
    onNetworksChange: function onNetworksChange(newNetworks) {
      if (newNetworks.length > 0) {
        this.updateSlides();
      }
    },
    lineDHover: function lineDHover(dateLabel, dataValue) {
      this.lineD = {
        totalBalance: dataValue,
        time: dateLabel
      };
    },
    getLineDate: function getLineDate() {
      var _this = this;
      try {
        var params = {
          period: this.defaultTab
        };
        if (this.chainId) {
          params.chainId = this.chainId;
        }
        (0, _api.lineChartDetailApi)([params]).then(function (r) {
          var res = r.result;
          _this.lineData = {
            x: _.map(res, 'time'),
            y: _.map(res, 'totalBalance')
          };
          _this.lineD = {
            totalBalance: res !== null && res !== void 0 && res.length ? res[res.length - 1].totalBalance : 0,
            time: res !== null && res !== void 0 && res.length ? (0, _utils.dayFormatUTC)(res[res.length - 1].time) : '--'
          };
        });
      } catch (e) {
        console.log(e, 'error');
      }
    },
    handleTab: function handleTab(val) {
      this.defaultTab = val;
      this.getLineDate();
    },
    currentSlideIdx: function currentSlideIdx(val) {
      var name = this.slides[val].secTitle;
      var chainId = this.slides[val].chainId;
      this.chainId = chainId;
      this.lineTitle = name;
      this.getLineDate();
    },
    updateSlides: function updateSlides() {
      var _this2 = this;
      try {
        (0, _account.accountDetailApi)([{
          accountHash: _config.GLOBAL_ACCOUNT
        }]).then(function (response) {
          var slideData = response.result.balanceDetails;
          var chainIds = (0, _toConsumableArray2.default)(new Set(slideData.map(function (v) {
            return Number(v.subAccountId);
          })));
          var networks = _this2.networks.filter(function (v) {
            return chainIds.includes(v.layerTwoChainId);
          });
          networks = networks.map(function (v) {
            var d = _this2.filters(slideData, v.layerTwoChainId);
            var total = _this2.totalValue(d);
            return _objectSpread(_objectSpread({}, v), {}, {
              value: total
            });
          });
          for (var i = 0; i < networks.length; i++) {
            var _networks$i, _networks$i2, _networks$i3;
            var coin = _this2.filters(slideData, networks[i].layerTwoChainId);
            _this2.slides.push({
              secTitle: (_networks$i = networks[i]) === null || _networks$i === void 0 ? void 0 : _networks$i.name,
              symbol: (_networks$i2 = networks[i]) === null || _networks$i2 === void 0 ? void 0 : _networks$i2.symbol,
              chainId: (_networks$i3 = networks[i]) === null || _networks$i3 === void 0 ? void 0 : _networks$i3.layerTwoChainId,
              data: _this2.sliderCoinData(coin)
            });
          }
          if (_this2.networks.length > 0) {
            var obj = {
              secTitle: 'All',
              symbol: '',
              chainId: 0,
              data: networks
            };
            _this2.slides.unshift(obj);
            _this2.slides.push(obj);
          }
        });
      } catch (e) {
        console.log(e, 'error');
      }
    },
    sliderCoinData: function sliderCoinData(data) {
      var _this3 = this;
      return data.map(function (v) {
        var name = v.symbol.indexOf('-') !== -1 ? v.symbol.split('-')[0] : v.symbol;
        return {
          value: v.value,
          name: name,
          color: _this3.getRandomColor()
        };
      });
    },
    filters: function filters(data, value) {
      if (value || value === 0) {
        return data.filter(function (v) {
          return v.subAccountId * 1 === value;
        });
      } else {
        return data;
      }
    },
    totalValue: function totalValue(data) {
      try {
        var init = [];
        var deci = [];
        for (var i = 0; i < data.length; i++) {
          if (data[i].value && data[i].value.includes('.')) {
            var arr = data[i].value.split('.');
            init.push(arr[0]);
            deci.push("0.".concat(arr[1]));
          } else {
            init.push(data.value);
          }
        }
        var a = init.reduce(function (pre, cur) {
          return (0, _bignumber.default)(pre) * 1 + (0, _bignumber.default)(cur) * 1;
        }, 0);
        var b = deci.reduce(function (pre, cur) {
          return pre * 1 + cur * 1;
        }, 0);
        return (0, _bignumber.default)(a).plus(b).toString();
      } catch (e) {
        console.log(e, 'error');
      }
    },
    findMaxAndMin: function findMaxAndMin(arr) {
      if (arr.length === 0) {
        return {
          max: 0,
          min: 0
        };
      }
      var minValue = arr.reduce(function (pre, cur) {
        return pre < cur ? pre : cur;
      });
      var maxValue = arr.reduce(function (pre, cur) {
        return pre > cur ? pre : cur;
      });
      var max = (0, _utils.paddingNum)(maxValue);
      var min = (0, _utils.paddingNum)(minValue);
      return {
        max: max,
        min: min
      };
    },
    statusInfo2: function statusInfo2(status) {
      return _.find(_config.TRANSACTION_STATUS, {
        value: status
      }) || {};
    },
    activeLink: function activeLink(val) {
      var path = '';
      path = val === 0 ? 'blocks' : 'transactions';
      this.$router.push(path);
    }
  }
};
exports.default = _default;