"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.number.constructor");
var _config = require("@/config");
var _api = require("@/api");
var _utils = require("@/utils");
var _Link = _interopRequireDefault(require("@/components/Link"));
var _Tip = _interopRequireDefault(require("@/components/Tip"));
//
//
//
//
var _default2 = {
  name: 'BlockComponents',
  props: {
    configMore: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    inOut: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 20
    },
    isPage: {
      type: Boolean,
      default: true
    },
    isShowFitter: {
      type: Boolean,
      default: true
    },
    blockHeight: {
      type: String,
      default: ''
    },
    accountHash: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    var _this = this;
    var h = this.$createElement;
    return {
      statusValue: '',
      config: {
        shadowNone: true,
        tableView: [{
          label: 'Block',
          key: 'blockHeight',
          align: 'left',
          formatter: function formatter(row, column, cellValue, index) {
            return cellValue ? h(_Link.default, {
              "attrs": {
                "href": "/block/".concat(cellValue),
                "isBlank": false,
                "text": cellValue
              }
            }) : '--';
          }
        }, {
          label: 'Created at',
          key: 'createdAt',
          align: 'left',
          minWidth: 160,
          formatter: function formatter(row, column, cellValue) {
            return cellValue ? h(_Tip.default, {
              "attrs": {
                "placement": 'top',
                "isNormal": true,
                "text": (0, _utils.customFormatUTC)(cellValue)
              }
            }, [h("span", [(0, _utils.dayFormatUTC)(cellValue)])]) : '--';
          }
        }, {
          label: 'Txn',
          key: 'txs',
          align: 'left'
        }, {
          label: 'Status',
          key: 'status',
          align: 'left',
          minWidth: 140,
          formatter: function formatter(row, column, cellValue, index) {
            return h("div", {
              "class": 'statusBlock'
            }, [cellValue === 3 ? h(_Tip.default, {
              "attrs": {
                "placement": 'top',
                "isNormal": true,
                "text": 'L2 Completed'
              }
            }, [h("span", ["The transaction has been included ", h("br"), "in a zkLink Layer2 block."])]) : h("span", {
              "attrs": {
                "className": 'statusText'
              }
            }, [_this.statusInfo2(cellValue).label]), h("span", {
              "class": "statusIcon ".concat(_this.statusInfo2(cellValue).style)
            })]);
          }
        }],
        tableListUrl: function tableListUrl(query) {
          return (0, _api.blockListApi)([{
            'pageNo': query.page,
            'pageSize': query.limit
          }]).then(function (response) {
            return {
              list: response.result.data,
              total: response.result.totalPages
            };
          });
        }
      }
    };
  },
  methods: {
    statusValueSelectChange: function statusValueSelectChange(e) {
      this.statusValue = e;
      this.$refs.tableRef.reload();
    },
    showDetail: function showDetail(row) {
      this.$router.push("/block/".concat(row.blockHeight));
    },
    statusInfo2: function statusInfo2(status) {
      return _.find(_config.TRANSACTION_STATUS, {
        value: status
      }) || {};
    }
  }
};
exports.default = _default2;