"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.networkApi = networkApi;
var _axios = _interopRequireDefault(require("axios"));
var _config = require("@/config");
function networkApi() {
  return _axios.default.get("".concat(_config.STATIC_HOST, "/networks/list.json"));
}