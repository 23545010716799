"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    textToCopy: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: 'Copy TxHash to clipboard'
    }
  },
  data: function data() {
    return {
      isCopy: false
    };
  },
  methods: {
    onClickCopy: function onClickCopy() {
      var _this = this;
      this.isCopy = true;
      setTimeout(function () {
        _this.isCopy = false;
      }, 1000);
      this.$copyText(this.textToCopy);
    }
  }
};
exports.default = _default;