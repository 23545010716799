"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TRANSACTION_TYPE = exports.TRANSACTION_STATUS = exports.SUB_ACCOUNT_IDS = exports.SUB_ACCOUNT_ID = exports.STATIC_HOST = exports.GLOBAL_ACCOUNT = exports.CHANGE_LINK = exports.API_HOST = void 0;
// prod->aws , dev->H.k
var STATIC_HOST = process.env.VUE_APP_STATIC_HOST;
exports.STATIC_HOST = STATIC_HOST;
var API_HOST = process.env.VUE_APP_API_HOST;
exports.API_HOST = API_HOST;
var GLOBAL_ACCOUNT = process.env.VUE_APP_GLOBAL_ACCOUNT;
exports.GLOBAL_ACCOUNT = GLOBAL_ACCOUNT;
var CHANGE_LINK = process.env.VUE_APP_CHANGE_NAME;
exports.CHANGE_LINK = CHANGE_LINK;
var TRANSACTION_STATUS = [{
  label: 'L2 Executed',
  style: 'statusIconPending',
  color: '#16C784',
  value: 1
}, {
  label: 'L1 Completed',
  style: 'statusIconCompleted',
  color: '#16C784',
  value: 2
}, {
  label: 'L2 Completed',
  style: 'statusIconCompleted2',
  color: '#16C784',
  value: 3
}
// { label: 'Failed', style: 'statusIconFailed', color: '#DB252F', value: 4, },
];
exports.TRANSACTION_STATUS = TRANSACTION_STATUS;
var TRANSACTION_TYPE = [{
  label: 'Deposit',
  value: 1
}, {
  label: 'Withdraw',
  value: 3
}, {
  label: 'Transfer',
  value: 4
}, {
  label: 'FullExit',
  value: 5
}, {
  label: 'ChangePubKey',
  value: 6
}, {
  label: 'ForcedExit',
  value: 7
}, {
  label: 'OrderMatching',
  value: 8
}];
exports.TRANSACTION_TYPE = TRANSACTION_TYPE;
var SUB_ACCOUNT_ID = [{
  label: 'zkLink',
  style: 'chain-icon zklink-color',
  value: 0,
  name: 'zkLink'
}, {
  label: 'ZKEX',
  style: 'chain-icon zkex-color',
  value: 1,
  name: 'ZKEX'
}, {
  label: 'zkJump',
  style: 'chain-icon zkJump-color',
  value: 3,
  name: 'zkJump'
}, {
  label: 'OpenWorld',
  style: 'chain-icon OpenWorld-color',
  value: 4,
  name: 'OpenWorld'
}];
exports.SUB_ACCOUNT_ID = SUB_ACCOUNT_ID;
var SUB_ACCOUNT_IDS = [0, 1, 3, 4];
exports.SUB_ACCOUNT_IDS = SUB_ACCOUNT_IDS;