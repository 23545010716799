"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _typeof2 = _interopRequireDefault(require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || (0, _typeof2.default)(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
_vue.default.use(_vueRouter.default);

/* Layout */

var constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/search404',
  component: _layout.default,
  name: 'searchNotFound',
  meta: {
    title: 'searchNotFound',
    icon: 'searchNotFound',
    affix: true
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/error-page/search404'));
      });
    },
    name: 'searchNotFound',
    meta: {
      title: '',
      icon: 'searchNotFound',
      affix: true
    }
  }]
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: '/dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    name: 'dashboard',
    meta: {
      title: 'Overview',
      icon: 'dashboard',
      affix: true
    }
  }, {
    path: '/address/:hash',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/index/accountsDetail.vue'));
      });
    },
    name: 'accounts',
    meta: {
      title: 'Accounts Detail',
      icon: 'accounts',
      affix: true
    }
  }, {
    path: 'tx/:hash',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/index/transactionsDetail.vue'));
      });
    },
    name: 'tx',
    meta: {
      title: 'transactions',
      icon: 'transactions',
      affix: true
    }
  }, {
    path: 'block/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/index/blocksDetail.vue'));
      });
    },
    name: 'block',
    meta: {
      title: 'blocks',
      icon: 'blocks',
      affix: true
    }
  }]
}, {
  path: '/transactions',
  component: _layout.default,
  name: 'transactions',
  meta: {
    title: 'Transactions',
    icon: 'transactions',
    affix: true
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/index/transactions.vue'));
      });
    },
    name: 'transactions',
    meta: {
      title: '',
      icon: 'transactions',
      affix: true
    }
  }]
}, {
  path: '/blocks',
  component: _layout.default,
  name: 'blocks',
  meta: {
    title: 'Blocks',
    icon: 'blocks',
    affix: true
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/index/blocks.vue'));
      });
    },
    name: 'blocks',
    meta: {
      title: '',
      icon: 'blocks',
      affix: true
    }
  }]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
exports.constantRoutes = constantRoutes;
var asyncRoutes = [];
exports.asyncRoutes = asyncRoutes;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
router.afterEach(function (to, from, next) {
  var appMain = document.querySelector('.app-main');
  appMain && appMain.scrollTo(0, 0);
});

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = router;
exports.default = _default;