var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticClass: "app-header" }, [_vm._v("Block")]),
    _vm._v(" "),
    _c("div", { staticClass: "pageContentWrap" }, [_c("BlockComponents")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }