var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tableInner",
      class: _vm.config.shadowNone ? "" : "tableInnerShadow",
    },
    [
      _vm._t("tableHeader"),
      _vm._v(" "),
      _c(
        "el-table",
        { attrs: { data: _vm.list }, on: { "sort-change": _vm.sortChange } },
        _vm._l(_vm.config.tableView, function (config, index) {
          return _c("el-table-column", {
            key: index,
            attrs: {
              label: config.label,
              prop: config.key,
              align: config.align || "right",
              width: config.width,
              "min-width": config.minWidth || 120,
              formatter: config.formatter,
              sortable: config.sortable,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.isPage
        ? _c(
            "div",
            { staticClass: "pageController" },
            [
              _vm.modelGet("app.device") === "desktop"
                ? _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.listQuery.page,
                      "page-sizes": [10, 20, 30, 40],
                      "page-size": _vm.limit,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.modelGet("app.device") === "mobile"
                ? _c("el-pagination", {
                    attrs: {
                      "page-size": _vm.limit,
                      "current-page": _vm.listQuery.page,
                      layout: "prev, pager, next",
                      total: _vm.total,
                    },
                    on: { "current-change": _vm.pageChange },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "pageController hide_hide" }, [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.listQuery.page > 1,
                expression: "listQuery.page > 1",
              },
            ],
            staticClass: "pre",
          },
          [
            _c("i", {
              staticClass: "el-icon-arrow-left",
              on: { click: _vm.pagePre },
            }),
          ]
        ),
        _vm._v(
          "\n    " +
            _vm._s(_vm.$i18n.locale === "zh-CN" ? "页码" : "Page") +
            " " +
            _vm._s(_vm.listQuery.page) +
            " / " +
            _vm._s(_vm.pageMax()) +
            "\n    "
        ),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.listQuery.page < _vm.pageMax(),
                expression: "listQuery.page < pageMax()",
              },
            ],
            staticClass: "next",
          },
          [
            _c("i", {
              staticClass: "el-icon-arrow-right",
              on: { click: _vm.pageNext },
            }),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }