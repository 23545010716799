import { render, staticRenderFns } from "./BreadCrumb.vue?vue&type=template&id=218c377a&scoped=true&"
import script from "./BreadCrumb.vue?vue&type=script&lang=js&"
export * from "./BreadCrumb.vue?vue&type=script&lang=js&"
import style0 from "./BreadCrumb.vue?vue&type=style&index=0&id=218c377a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "218c377a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/zklink/web/zklink-explorer-web-build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('218c377a')) {
      api.createRecord('218c377a', component.options)
    } else {
      api.reload('218c377a', component.options)
    }
    module.hot.accept("./BreadCrumb.vue?vue&type=template&id=218c377a&scoped=true&", function () {
      api.rerender('218c377a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/componentsG/BreadCrumb.vue"
export default component.exports