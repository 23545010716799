"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _router = _interopRequireDefault(require("./router"));
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
// progress bar
// progress bar style

_nprogress.default.configure({
  showSpinner: false
}); // NProgress Configuration

_router.default.afterEach(function () {
  // finish progress bar
  _nprogress.default.done();
});