var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "show-wrap",
      on: {
        click: function ($event) {
          return _vm.show()
        },
      },
    },
    [
      _vm.list.length > 0
        ? _c(
            "div",
            _vm._l(_vm.chainSort(_vm.list), function (item, index) {
              return _c(
                "a",
                {
                  key: index,
                  staticClass: "linkChain",
                  attrs: {
                    href: _vm.l1ExploreTxUrl(item.chain_id, item.l1_hash),
                    target: "_blank",
                  },
                },
                [
                  _c("span", { staticClass: "textClickable" }, [
                    _vm._v(_vm._s(item.l1_hash)),
                  ]),
                  _vm._v(" "),
                  _c("span", { class: _vm.findNetwork(item.chain_id).style }, [
                    _vm._v(_vm._s(_vm.findNetwork(item.chain_id).name)),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }