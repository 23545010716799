"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("./waves.css");
var context = '@@wavesContext';
function handleClick(el, binding) {
  function handle(e) {
    var customOpts = Object.assign({}, binding.value);
    var opts = Object.assign({
      ele: el,
      type: 'hit',
      color: 'rgba(0, 0, 0, 0.15)'
    }, customOpts);
    var target = opts.ele;
    if (target) {
      target.style.position = 'relative';
      target.style.overflow = 'hidden';
      var rect = target.getBoundingClientRect();
      var ripple = target.querySelector('.waves-ripple');
      if (!ripple) {
        ripple = document.createElement('span');
        ripple.className = 'waves-ripple';
        ripple.style.height = ripple.style.width = Math.max(rect.width, rect.height) + 'px';
        target.appendChild(ripple);
      } else {
        ripple.className = 'waves-ripple';
      }
      switch (opts.type) {
        case 'center':
          ripple.style.top = rect.height / 2 - ripple.offsetHeight / 2 + 'px';
          ripple.style.left = rect.width / 2 - ripple.offsetWidth / 2 + 'px';
          break;
        default:
          ripple.style.top = (e.pageY - rect.top - ripple.offsetHeight / 2 - document.documentElement.scrollTop || document.body.scrollTop) + 'px';
          ripple.style.left = (e.pageX - rect.left - ripple.offsetWidth / 2 - document.documentElement.scrollLeft || document.body.scrollLeft) + 'px';
      }
      ripple.style.backgroundColor = opts.color;
      ripple.className = 'waves-ripple z-active';
      return false;
    }
  }
  if (!el[context]) {
    el[context] = {
      removeHandle: handle
    };
  } else {
    el[context].removeHandle = handle;
  }
  return handle;
}
var _default = {
  bind: function bind(el, binding) {
    el.addEventListener('click', handleClick(el, binding), false);
  },
  update: function update(el, binding) {
    el.removeEventListener('click', el[context].removeHandle, false);
    el.addEventListener('click', handleClick(el, binding), false);
  },
  unbind: function unbind(el) {
    el.removeEventListener('click', el[context].removeHandle, false);
    el[context] = null;
    delete el[context];
  }
};
exports.default = _default;