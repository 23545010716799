var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.modelGet("app.settingsBottomOpen")
      ? _c("div", { staticClass: "settingsBottomModalWrap" }, [
          _c("div", { staticClass: "settingInner" }, [
            _c("div", { staticClass: "settingInnerHeader" }, [
              _c("span", { staticClass: "sHTitle" }, [
                _vm._v("Select network"),
              ]),
              _vm._v(" "),
              _c("span", {
                staticClass: "sHClose",
                on: { click: _vm.settingModalClose },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "settingInnerContent" },
              [
                _c("div", { staticClass: "settingLi" }, [
                  _c("span", { staticClass: "settingLiLabel" }, [
                    _vm._v(
                      _vm._s(
                        _vm.modelGet("app.theme") === "dark" ? "Dark" : "Light"
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "btnThemeChange",
                    class:
                      _vm.modelGet("app.theme") === "dark"
                        ? "btnDark"
                        : "btnLight",
                    on: { click: _vm.themeChange },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "top",
                      width: "",
                      "popper-class": "englishPop",
                      trigger: "click",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "optionList" },
                      _vm._l(_vm.options, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.value,
                            staticClass: "optionLi",
                            on: {
                              click: function ($event) {
                                return _vm.langChange(item.value)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.label))]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "settingLi",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [
                        _c("span", { staticClass: "settingLiLabel" }, [
                          _vm._v(_vm._s(_vm.langChangeValue())),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "settingLiOpt" }),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }