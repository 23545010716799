"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _networkUtilsMixin = _interopRequireDefault(require("@/constants/networkUtilsMixin"));
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  mixins: [_networkUtilsMixin.default],
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      isShow: false
    };
  },
  computed: {},
  methods: {
    show: function show() {
      this.isShow = !this.isShow;
    },
    chainSort: function chainSort(data) {
      return _.orderBy(data, ['chain_id'], ['asc']);
    }
  }
};
exports.default = _default2;