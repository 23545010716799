var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isNormal
    ? _c(
        "span",
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: { effect: "dark", placement: _vm.placement },
            },
            [
              _c(
                "div",
                { attrs: { slot: "content" }, slot: "content" },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              !_vm.isJumpTip
                ? _c("svg-icon", { attrs: { "icon-class": "tip-icon" } })
                : _c("svg-icon", { attrs: { "icon-class": "Jump-icon" } }),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "span",
        [
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: { effect: "dark", placement: _vm.placement },
            },
            [
              _c(
                "div",
                { attrs: { slot: "content" }, slot: "content" },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.text))]),
            ]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }