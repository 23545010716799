var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "app-header" }, [_vm._v("Block")]),
      _vm._v(" "),
      _c("div", { staticClass: "detailInfo" }, [
        _c("table", [
          _c("tbody", [
            _c("tr", [
              _c(
                "td",
                [
                  _c("Tip", { attrs: { placement: "right" } }, [
                    _c("span", [
                      _vm._v(
                        "\n                Also known as Block Number.The block height,"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                which indicates the length of the blockchain,"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                increases after the addition of the new block.\n              "
                      ),
                    ]),
                  ]),
                  _vm._v(" Block Height：\n          "),
                ],
                1
              ),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.blockHeight))]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                [
                  _c("Tip", { attrs: { placement: "right" } }, [
                    _c("span", [
                      _vm._v("\n                The hash of the block header"),
                      _c("br"),
                      _vm._v(
                        "\n                of the current block.\n              "
                      ),
                    ]),
                  ]),
                  _vm._v(" Block Hash：\n          "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.detailInfo.blockHash) +
                      "\n            "
                  ),
                  _c("CopyToClipboard", {
                    attrs: {
                      textToCopy: _vm.detailInfo.blockHash,
                      copied: "handleCopy",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                [
                  _c("Tip", { attrs: { placement: "right" } }, [
                    _c("span", [
                      _vm._v("\n                The date and time at which"),
                      _c("br"),
                      _vm._v(
                        "\n                a block is produced.\n              "
                      ),
                    ]),
                  ]),
                  _vm._v(" Created at：\n          "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("svg-icon", { attrs: { "icon-class": "clock-icon" } }),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.customFormatUTC(_vm.detailInfo.createdAt)) +
                      "\n            (" +
                      _vm._s(_vm.dayFormatUTC(_vm.detailInfo.createdAt)) +
                      ")\n          "
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                [
                  _c("Tip", { attrs: { placement: "right" } }, [
                    _c("span", [
                      _vm._v(
                        "\n                The number of transactions in the block."
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                Internal transaction is transactions as"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                a result of contract execution that"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                involves Ether value.\n              "
                      ),
                    ]),
                  ]),
                  _vm._v(" Transactions：\n          "),
                ],
                1
              ),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.detailInfo.transactions))]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c(
                "td",
                [
                  _c("Tip", { attrs: { placement: "right" } }, [
                    _c("span", [
                      _vm._v(
                        "\n                The finality status of the block.\n              "
                      ),
                    ]),
                  ]),
                  _vm._v(" Status：\n          "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                [_c("Status", { attrs: { status: _vm.detailInfo.status } })],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "detailInfo detailInfo2" }, [
        _c("table", [
          _c("tbody", [
            _vm._m(0),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Committed：")]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("BlockTokenListShow", {
                    attrs: { list: _vm.detailInfo.committed },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Proved：")]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("BlockTokenListShow", {
                    attrs: { list: _vm.detailInfo.proved },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Executed：")]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("BlockTokenListShow", {
                    attrs: { list: _vm.detailInfo.executed },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "app-header" }, [_vm._v("Transactions")]),
      _vm._v(" "),
      _c("TransactionsComponents", {
        attrs: { "is-show-fitter": false, "block-height": _vm.blockHeight },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticStyle: { "font-weight": "700" } }, [
        _vm._v("L1 details"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }