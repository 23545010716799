"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.filterAsyncRoutes = filterAsyncRoutes;
exports.filterAsyncRoutesWithRoleNames = filterAsyncRoutesWithRoleNames;
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _router = require("@/router");
var _lodash = _interopRequireDefault(require("lodash"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}
function filterAsyncRoutesWithRoleNames(routes, roleNames) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (_lodash.default.includes(roleNames, tmp.name) || _lodash.default.some(tmp.children, function (nn, ii) {
      return _lodash.default.includes(roleNames, nn.name);
    })) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutesWithRoleNames(tmp.children, roleNames);
      }
      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes;
      // if (roles.includes('admin')) {
      //   accessedRoutes = asyncRoutes || []
      // } else {
      accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, roles);
      // }
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  },
  generateRoutesWithRoleNames: function generateRoutesWithRoleNames(_ref2, roleNames) {
    var commit = _ref2.commit;
    return new Promise(function (resolve) {
      var accessedRoutes;
      // if (roles.includes('admin')) {
      //   accessedRoutes = asyncRoutes || []
      // } else {
      accessedRoutes = filterAsyncRoutesWithRoleNames(_router.asyncRoutes, roleNames);
      // }
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;