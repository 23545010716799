"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Link',
  props: {
    href: {
      type: String,
      default: '/'
    },
    isBlank: {
      type: Boolean,
      default: true
    },
    text: {
      type: [String, Number],
      default: ''
    }
  }
};
exports.default = _default;