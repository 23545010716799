"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lang = void 0;
var lang = {
  langMsg: '简体中文',
  'test.test': ''
};
exports.lang = lang;