var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "carousel-wrap" }, [
    _c(
      "div",
      { staticClass: "carousel" },
      [
        _c("transition", { attrs: { name: "slide-fade", mode: "out-in" } }, [
          _c(
            "div",
            {
              ref: "slideContainer",
              staticClass: "slide-container",
              style: { transform: "translateX(" + _vm.offset + "%)" },
            },
            [_vm._t("default")],
            2
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "prev-button", on: { click: _vm.prevSlide } },
      [_c("svg-icon", { attrs: { "icon-class": "arrow" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      { staticClass: "next-button", on: { click: _vm.nextSlide } },
      [_c("svg-icon", { attrs: { "icon-class": "arrow" } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }