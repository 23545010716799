var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isBlank
    ? _c(
        "a",
        {
          staticClass: "link_jump",
          attrs: { href: _vm.href, target: "_blank" },
        },
        [_vm._v("\n  " + _vm._s(_vm.text) + "\n")]
      )
    : _c("router-link", { staticClass: "link_jump", attrs: { to: _vm.href } }, [
        _vm._v("\n  " + _vm._s(_vm.text) + "\n"),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }