var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "searchContainer" }, [
      _c("div", [
        _c("div", { staticClass: "picWrap" }, [
          _c("img", {
            staticClass: "picIcon",
            attrs: {
              src: require("@/assets/404_images/searchNotFound.png"),
              alt: "404",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "tipWrap" }, [
          _c("div", { staticClass: "tipTitle" }, [_vm._v("Search not found")]),
          _vm._v(" "),
          _c("p", { staticClass: "tipText" }, [
            _vm._v(
              "\n        This account doesn’t have any transactions.\n      "
            ),
          ]),
          _vm._v(" "),
          _c("a", { staticClass: "tipButton", attrs: { href: "/" } }, [
            _vm._v("Back Home"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }