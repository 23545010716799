var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _c(
            "router-link",
            {
              key: "collapse",
              staticClass: "sidebar-logo-link",
              attrs: { to: "/" },
            },
            [
              _c("div", { staticClass: "sidebar-logo" }),
              _vm._v(" "),
              !_vm.collapse
                ? _c("div", { staticClass: "sidebar-title" })
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }