"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BlocksComponent = _interopRequireDefault(require("./BlocksComponent.vue"));
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Block',
  components: {
    BlockComponents: _BlocksComponent.default
  }
};
exports.default = _default;