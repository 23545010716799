"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _toConsumableArray2 = _interopRequireDefault(require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.number.constructor");
var _utils = require("@/utils");
var _config = require("@/config");
var _api = require("@/api");
var _networkUtilsMixin = _interopRequireDefault(require("@/constants/networkUtilsMixin"));
var _Link = _interopRequireDefault(require("@/components/Link"));
var _Tip = _interopRequireDefault(require("@/components/Tip"));
var _CopyToClipboard = _interopRequireDefault(require("@/components/CopyToClipboard"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var _default2 = {
  name: 'TransactionsComponents',
  directives: {},
  filters: {},
  mixins: [_networkUtilsMixin.default],
  props: {
    configMore: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    inOut: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 20
    },
    isPage: {
      type: Boolean,
      default: true
    },
    isShowFitter: {
      type: Boolean,
      default: true
    },
    blockHeight: {
      type: String,
      default: ''
    },
    accountHash: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    var _this = this;
    var h = this.$createElement;
    return {
      detailInfo: {},
      transType: Number(this.$route.query.type) || '',
      statusValue: Number(this.$route.query.status) || '',
      options: [{
        label: 'All',
        value: ''
      }].concat((0, _toConsumableArray2.default)(_config.TRANSACTION_TYPE)),
      statusOption: [{
        label: 'All',
        value: ''
      }].concat((0, _toConsumableArray2.default)(_config.TRANSACTION_STATUS)),
      config: _objectSpread(_objectSpread({}, this.configMore), {}, {
        tableView: [{
          label: 'Txn Hash',
          key: 'txnHash',
          align: 'left',
          minWidth: 145,
          formatter: function formatter(row, column, cellValue) {
            return h("div", [h(_Link.default, {
              "attrs": {
                "href": "/tx/".concat(row.txnHash),
                "isBlank": false,
                "text": (0, _utils.getSubStr)(cellValue)
              }
            }), h(_CopyToClipboard.default, {
              "attrs": {
                "textToCopy": cellValue
              }
            })]);
          }
        }, {
          label: 'Type',
          key: 'opType',
          align: 'left',
          formatter: function formatter(row, column, cellValue, index) {
            return h("span", [_this.typeInfo(cellValue).label, row.opType === 3 && row.fastWithdraw ? h("span", {
              "class": 'chain-icon fast-color'
            }, ["fast"]) : null]);
          }
        }, {
          label: 'Block Height',
          key: 'blockHeight',
          align: 'left',
          formatter: function formatter(row, column, cellValue, index) {
            return cellValue ? h(_Link.default, {
              "attrs": {
                "href": "/block/".concat(row.blockHeight),
                "isBlank": false,
                "text": cellValue
              }
            }) : '--';
          }
        }, {
          label: 'Created at',
          key: 'l2Completed',
          align: 'left',
          minWidth: 150,
          formatter: function formatter(row, column, cellValue, index) {
            return cellValue ? h(_Tip.default, {
              "attrs": {
                "placement": 'top',
                "isNormal": true,
                "text": (0, _utils.customFormatUTC)(cellValue)
              }
            }, [h("span", [(0, _utils.dayFormatUTC)(cellValue)])]) : '--';
          }
        }, {
          label: 'From',
          key: 'from',
          align: 'left',
          minWidth: 220,
          formatter: function formatter(row, column, cellValue, index) {
            return h("div", [h(_Link.default, {
              "attrs": {
                "href": cellValue.level === 'L1' ? _this.l1ExploreAddress(cellValue.value, cellValue.hash) : "/address/".concat(cellValue.hash),
                "isBlank": cellValue.level === 'L1',
                "text": (0, _utils.getSubStr)((0, _utils.erc20Address)(cellValue.hash))
              }
            }), h(_CopyToClipboard.default, {
              "attrs": {
                "textToCopy": (0, _utils.erc20Address)(cellValue.hash),
                "content": 'Copy Address'
              }
            }), row.opType !== 6 ? h("span", {
              "class": _this.iconInfo(cellValue).style
            }, [_this.iconInfo(cellValue).name]) : null]);
          }
        }, {
          label: 'To',
          key: 'to',
          align: 'left',
          minWidth: 220,
          formatter: function formatter(row, column, cellValue, index) {
            return h("div", [h(_Link.default, {
              "attrs": {
                "href": cellValue.level === 'L1' ? _this.l1ExploreAddress(cellValue.value, cellValue.hash) : "/address/".concat(cellValue.hash),
                "isBlank": cellValue.level === 'L1',
                "text": (0, _utils.getSubStr)((0, _utils.erc20Address)(cellValue.hash))
              }
            }), h(_CopyToClipboard.default, {
              "attrs": {
                "textToCopy": (0, _utils.erc20Address)(cellValue.hash),
                "content": 'Copy Address'
              }
            }), row.opType !== 6 ? h("span", {
              "class": _this.iconInfo(cellValue).style
            }, [_this.iconInfo(cellValue).name]) : null]);
          }
        }, {
          label: 'Status',
          key: 'status',
          align: 'left',
          minWidth: 140,
          formatter: function formatter(row, column, cellValue, index) {
            return h("div", {
              "class": 'statusBlock'
            }, [h("span", [_this.statusInfo(cellValue).label]), h("span", {
              "class": "statusIcon ".concat(_this.statusInfo(cellValue).style)
            })]);
          }
        }],
        tableListUrl: function tableListUrl(query) {
          var params = {
            'pageNo': query.page,
            'pageSize': query.limit
          };
          if (_this.transType) {
            params.opType = Number(_this.transType);
          }
          if (_this.statusValue) {
            params.status = Number(_this.statusValue);
          }
          if (_this.accountHash) {
            params.accountHash = _this.accountHash;
          }
          if (_this.blockHeight) {
            params.blockHeight = Number(_this.blockHeight);
          }
          return (0, _api.transactionsListApi)([params]).then(function (response) {
            return {
              list: response.result.data,
              total: response.result.totalPages
            };
          });
        }
      })
    };
  },
  methods: {
    numberFormat: _utils.numberFormat,
    numberFormatWidthUnit: _utils.numberFormatWidthUnit,
    dayFormatEnglish: _utils.dayFormatEnglish,
    typeInfo: function typeInfo(type) {
      return _.find(_config.TRANSACTION_TYPE, {
        value: type
      }) || {};
    },
    selectHandler: function selectHandler(e) {
      var _this$$route$query = this.$route.query,
        type = _this$$route$query.type,
        status = _this$$route$query.status;
      this.transType = e;
      var typeValue = e === '' ? '' : e || type;
      this.$router.push({
        path: this.$route.path,
        query: {
          p: 1,
          type: typeValue,
          status: status
        }
      });
      this.$refs.tableRef.reload();
    },
    statusValueSelectChange: function statusValueSelectChange(e) {
      var _this$$route$query2 = this.$route.query,
        type = _this$$route$query2.type,
        status = _this$$route$query2.status;
      this.statusValue = e;
      var statusValue = e === '' ? '' : e || status;
      this.$router.push({
        path: this.$route.path,
        query: {
          p: 1,
          type: type,
          status: statusValue
        }
      });
      this.$refs.tableRef.reload();
    },
    chooseChange: function chooseChange(n, i) {
      this.statusValue = n.value;
      this.$refs.tableRef.reload();
    },
    linkDetail: function linkDetail(path) {
      this.$router.push(path);
    },
    showBlockDetail: function showBlockDetail(row) {
      if (row.status === 1 || row.status === 4) {
        return null;
      } else {
        this.$router.push("/block/".concat(row.blockHeight));
      }
    }
  }
};
exports.default = _default2;