"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
var _defineProperty2 = _interopRequireDefault(require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _request = _interopRequireDefault(require("@/utils/request"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var _default = {
  name: 'TableShowWithDetail',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {},
  props: {
    config: {
      type: Object
    },
    limit: {
      type: Number,
      default: 20
    },
    isPage: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: false,
      listQuery: {
        page: Number(this.$route.query.p) || 1,
        limit: this.limit,
        sort: {}
      },
      temp: {},
      // detail
      rowChangeShow: false,
      rowDetailShow: false,
      rowChangeInfo: {},
      dialogStatus: '',
      textMap: {
        update: 'Update',
        create: 'Create',
        detail: 'Detail'
      },
      downloadLoading: false
    };
  },
  computed: {},
  created: function created() {
    this.getList();
  },
  methods: {
    sortChange: function sortChange(_ref) {
      var column = _ref.column,
        prop = _ref.prop,
        order = _ref.order;
      this.listQuery.sort = {
        by: prop,
        type: {
          'descending': 'desc',
          'ascending': 'asc'
        }[order]
      };
      this.listLoading = true;
      this.getList();
    },
    pageMax: function pageMax() {
      return Math.ceil(this.total / this.listQuery.limit);
    },
    pageChange: function pageChange(e) {
      var _this$$route$query = this.$route.query,
        p = _this$$route$query.p,
        type = _this$$route$query.type,
        status = _this$$route$query.status;
      this.listQuery.page = e;
      this.$router.push({
        path: this.$route.path,
        query: {
          p: e || p,
          type: type,
          status: status
        }
      });
      this.getList();
    },
    handleSizeChange: function handleSizeChange(e) {
      this.listQuery.limit = e;
      this.getList();
    },
    pagePre: function pagePre() {
      this.listQuery.page--;
      this.getList();
    },
    pageNext: function pageNext() {
      this.listQuery.page++;
      this.getList();
    },
    getIndexWithPage: function getIndexWithPage(index) {
      return (this.listQuery.page - 1) * this.listQuery.limit + index + 1;
    },
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      if (_.isFunction(this.config.tableListUrl)) {
        //
        this.listLoading = true;
        this.config.tableListUrl(this.listQuery).then(function (data) {
          _this.list = data.list;
          _this.total = data.total;
          _this.listLoading = false;
        });
      } else if (_.isString(this.config.tableListUrl)) {
        //
        (0, _request.default)({
          url: this.config.tableListUrl,
          method: 'post',
          data: _objectSpread({
            pageNumber: this.listQuery.page - 1,
            pageSize: this.listQuery.limit
          }, this.listQuery)
        }).then(function (response) {
          _this.list = response.body.content;
          _this.total = response.body.totalElements;
          _this.listLoading = false;
        });
      }
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    reload: function reload() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleView: function handleView(row) {
      this.temp = Object.assign({}, row); // copy obj
      this.dialogStatus = 'detail';
      this.rowDetailShow = true;
    },
    rowCreateDialogShow: function rowCreateDialogShow() {
      var _this2 = this;
      if (this.config.createHandle) {
        this.config.createHandle();
        return;
      }
      this.temp = {}; // copy obj
      this.dialogStatus = 'create';
      this.rowChangeShow = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    rowChangeDialogShow: function rowChangeDialogShow(row) {
      var _this3 = this;
      if (this.config.rowChangeDialogShow) {
        this.config.rowChangeDialogShow(row);
        return;
      }
      this.temp = Object.assign({}, row); // copy obj
      this.dialogStatus = 'update';
      this.rowChangeShow = true;
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();
      });
    },
    rowChangeHandle: function rowChangeHandle() {
      var _this4 = this;
      (0, _request.default)({
        url: this.config.tableLiUpdateUrl,
        method: 'post',
        data: _objectSpread({}, this.temp)
      }).then(function (response) {
        _this4.rowChangeShow = false;
        _this4.getList();
      });
    },
    rowDelete: function rowDelete(row) {
      var _this5 = this;
      (0, _request.default)({
        url: _.isFunction(this.config.tableLiDeleteUrl) ? this.config.tableLiDeleteUrl(row) : this.config.tableLiDeleteUrl,
        method: 'delete',
        data: {}
      }).then(function () {
        _this5.getList();
      });
    },
    getSortClass: function getSortClass(key) {
      var sort = this.listQuery.sort;
      return sort === "+".concat(key) ? 'ascending' : 'descending';
    }
  }
};
exports.default = _default;