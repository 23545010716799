var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("sidebarMobile", { staticClass: "sidebar-container-mobile" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: { "fixed-header": _vm.fixedHeader } },
        [
          _c("navbar"),
          _vm._v(" "),
          _vm.needTagsView ? _c("tags-view") : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: [_vm.needTagsView ? "hasTagsView" : ""],
          on: { click: _vm.handleClickOutside },
        },
        [_c("app-main")],
        1
      ),
      _vm._v(" "),
      _vm.device === "mobile" ? _c("settingsBottom") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }