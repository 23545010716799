"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _other = require("@/api/other");
var _default = {
  components: {},
  props: ['isHome'],
  data: function data() {
    return {
      searchValue: ''
    };
  },
  computed: {},
  methods: {
    searchHandler: function searchHandler(e) {
      var _this = this;
      if (_.size(this.searchValue.trim()) === 0) {
        return;
      }
      (0, _other.searchApi)([{
        'keywords': this.searchValue.trim()
      }]).then(function (res) {
        var hash = res.result;
        var path = '';
        if (!hash.detail) {
          path = '/search404';
        } else {
          if (hash.hashType === 'txn') {
            path = "/tx/".concat(hash.detail);
          } else if (hash.hashType === 'block') {
            path = "/block/".concat(hash.detail);
          } else if (hash.hashType === 'account') {
            path = "/address/".concat(hash.detail);
          }
        }
        _this.linkDetail(path);
      });
    },
    linkDetail: function linkDetail(path) {
      this.$router.push(path);
    }
  },
  render: function render() {
    var _this2 = this;
    var h = arguments[0];
    return h("div", {
      "class": 'searchModule',
      "on": {
        "click": function click(e) {
          return e.stopPropagation();
        }
      }
    }, [!this.isHome ? h("div", {
      "class": 'search1'
    }, [h("el-input", {
      "class": 'searchModuleInput',
      "attrs": {
        "placeholder": 'Search Tx Hash / Block / Account'
      },
      "on": {
        "change": this.searchHandler
      },
      "model": {
        value: _this2.searchValue,
        callback: function callback($$v) {
          _this2.searchValue = $$v;
        }
      }
    }), h("div", {
      "class": 'searchBtn searchBtn1',
      "on": {
        "click": this.searchHandler
      }
    }, [h("svg-icon", {
      "attrs": {
        "icon-class": 'search'
      }
    })])]) : h("div", {
      "class": 'search2'
    }, [h("el-input", {
      "class": 'searchModuleInput',
      "attrs": {
        "placeholder": 'Search Tx Hash / Block / Account'
      },
      "on": {
        "change": this.searchHandler
      },
      "model": {
        value: _this2.searchValue,
        callback: function callback($$v) {
          _this2.searchValue = $$v;
        }
      }
    }), h("div", {
      "class": 'searchBtn',
      "on": {
        "click": this.searchHandler
      }
    }, [h("svg-icon", {
      "attrs": {
        "icon-class": 'search'
      }
    })])])]);
  }
};
exports.default = _default;