"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    slidesLength: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      currentSlide: 0,
      isSliding: false,
      offset: 0
    };
  },
  methods: {
    prevSlide: function prevSlide() {
      var _this = this;
      if (!this.isSliding) {
        this.isSliding = true;
        var ele = this.$refs.slideContainer;
        if (this.currentSlide === 0) {
          ele.style.transition = 'none';
          this.offset = -100 * (this.slidesLength - 1);
          setTimeout(function () {
            ele.style.transition = 'transform 0.3s ease';
            _this.currentSlide = _this.slidesLength - 2;
            _this.offset = -100 * _this.currentSlide;
            _this.isSliding = false;
            _this.$parent.currentSlideIdx(_this.currentSlide);
          }, 1);
        } else {
          ele.style.transition = 'transform 0.3s ease';
          this.currentSlide = this.currentSlide - 1;
          this.offset = -100 * this.currentSlide;
          this.isSliding = false;
          this.$parent.currentSlideIdx(this.currentSlide);
        }
      }
    },
    nextSlide: function nextSlide() {
      var _this2 = this;
      if (!this.isSliding) {
        this.isSliding = true;
        var ele = this.$refs.slideContainer;
        if (this.currentSlide === this.slidesLength - 1) {
          this.offset = 0;
          ele.style.transition = 'none';
          setTimeout(function () {
            ele.style.transition = 'transform 0.3s ease';
            _this2.currentSlide = 1;
            _this2.offset = -100;
            _this2.isSliding = false;
            _this2.$parent.currentSlideIdx(_this2.currentSlide);
          }, 1);
        } else {
          ele.style.transition = 'transform 0.3s ease';
          this.currentSlide = this.currentSlide + 1;
          this.offset = -100 * this.currentSlide;
          this.isSliding = false;
          this.$parent.currentSlideIdx(this.currentSlide);
        }
      }
    }
  }
};
exports.default = _default;