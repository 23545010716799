"use strict";

var _interopRequireDefault = require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/home/zklink/web/zklink-explorer-web-build/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.number.constructor");
var _chart = _interopRequireDefault(require("chart.js"));
var _utils = require("@/utils");
//
//
//
//
//
//
var _default2 = {
  props: {
    width: {
      type: String,
      default: '200'
    },
    height: {
      type: String,
      default: '200'
    },
    inOut: {
      type: String,
      default: '128'
    },
    length: {
      type: Number,
      default: 10
    },
    lineData: {
      type: Object,
      default: function _default() {
        return {
          x: [],
          y: []
        };
      }
    }
  },
  data: function data() {
    var _this = this;
    return {
      chartInstance: null,
      chartData: {
        labels: this.lineData.x.length ? this.lineData.x.map(function (v) {
          return (0, _utils.dayFormatUTC)(v);
        }) : '--',
        datasets: [{
          label: 'value',
          borderColor: '#16C784',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          data: this.lineData.y
        }]
      },
      chartOptions: {
        legend: {
          display: false
        },
        elements: {
          point: {
            radius: function radius() {
              return _this.length > 20 ? 0 : 1;
            },
            hitRadius: 3,
            hoverRadius: 10,
            borderWidth: 3,
            borderColor: '#16C784',
            backgroundColor: '#16C784'
          }
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              display: false
            }
          }],
          yAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              display: false,
              maxTicksLimit: 2,
              callback: function callback(value, index, values) {
                if (index === 0) {
                  return "$".concat((0, _utils.paddingNum)(Math.max.apply(Math, (0, _toConsumableArray2.default)(_this.lineData.y))));
                }
                if (index === values.length - 1) {
                  return "$".concat((0, _utils.paddingNum)(Math.min.apply(Math, (0, _toConsumableArray2.default)(_this.lineData.y))));
                }
                return '';
              }
            }
          }]
        },
        tooltips: {
          callbacks: {
            label: function label(tooltipItem, data) {
              var dataIndex = tooltipItem.index;
              var value = data.datasets[0].data[dataIndex];
              var valueLine = "Value: $".concat((0, _utils.paddingNum)(value));
              return [valueLine];
            }
          }
        },
        layout: {
          padding: 10
        },
        onHover: function onHover(event, chartElements) {
          if (chartElements.length > 0) {
            var dataIndex = chartElements[0]._index;
            var dateLabel = chartElements[0]._chart.data.labels[dataIndex];
            var dataset = chartElements[0]._chart.data.datasets[0];
            var dataValue = dataset.data[dataIndex];
            _this.$parent.lineDHover(dateLabel, dataValue);
          } else {
            var d = _this.lineData;
            _this.$parent.lineDHover((0, _utils.dayFormatUTC)(d.x[d.x.length - 1]), d.y[d.y.length - 1]);
          }
        }
      }
    };
  },
  watch: {
    width: 'updateChartSize',
    height: 'updateChartSize',
    lineData: {
      handler: function handler(newPieData) {
        this.chartData.labels = newPieData.x.map(function (v) {
          return (0, _utils.dayFormatUTC)(v);
        });
        this.chartData.datasets[0].data = newPieData.y;
        this.renderChart();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.renderChart();
  },
  methods: {
    renderChart: function renderChart() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }
      var ctx = this.$refs.chartLineCanvas.getContext('2d');
      this.chartInstance = new _chart.default(ctx, {
        type: 'line',
        data: this.chartData,
        options: this.chartOptions
      });
    },
    updateChartSize: function updateChartSize() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }
      var ctx = this.$refs.chartLineCanvas.getContext('2d');
      ctx.canvas.width = this.width;
      ctx.canvas.height = this.height;
      this.chartInstance = new _chart.default(ctx, {
        type: 'line',
        data: this.chartData,
        options: this.chartOptions
      });
    }
  }
};
exports.default = _default2;