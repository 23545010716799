var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { "has-logo": _vm.showLogo } }, [
    _c("div", { staticClass: "leftHeader" }, [
      _c("div", { staticClass: "logoIcon" }),
      _vm._v(" "),
      _c("div", {
        staticClass: "closeBtn",
        on: { click: _vm.handleClickOutside },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "menuList", class: _vm.isCollapse ? "collapseStyle" : "" },
      [
        _vm._l(_vm.menuList, function (n, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass: "menuLi",
              class: [_vm.$route.name === n.name ? "menuLiActive" : ""],
              on: {
                click: function ($event) {
                  return _vm.$router.push(n.path)
                },
              },
            },
            [_c("span", { staticClass: "liText" }, [_vm._v(_vm._s(n.label))])]
          )
        }),
        _vm._v(" "),
        _c(
          "el-select",
          {
            staticClass: "selectStyle",
            staticStyle: { width: "110px" },
            attrs: { placeholder: "please select" },
            on: { change: _vm.selectHandler },
            model: {
              value: _vm.selectLink,
              callback: function ($$v) {
                _vm.selectLink = $$v
              },
              expression: "selectLink",
            },
          },
          _vm._l(_vm.selectLinkOptions, function (item) {
            return _c(
              "a",
              { key: item.value, attrs: { href: item.value } },
              [
                _c("el-option", {
                  attrs: { label: item.label, value: item.label },
                }),
              ],
              1
            )
          }),
          0
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }